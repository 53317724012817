.two-column-container {
  display: flex;
  flex-direction: row;
}

.two-column-container > .large-column {
  order: 1;
  width: 70%;
}

.two-column-container > .small-column {
  order: 2;
  width: 22%;
  margin-left: auto;
  overflow: scroll;
  height: 1100px;
}
