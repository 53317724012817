@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.app {
  background: #fbf7f0;
}

.bp3-button-group .bp3-button {
  z-index: 0
}
.bp3-button-group a:hover {
  text-decoration: none;
}

.no-underline a:hover {
  text-decoration: none;
}

.bg-hp-green { background: linear-gradient(135deg, #02a95c, #02874a) } 
.bg-hp-dark-green { color: #016637 }
.hp-sub-nav { background: #f0e2c8 } 
.two-column-container > .large-column { width: 83%}


.category-box {
  width: 100px;
  text-align:center;
  display:inline-block;
}

.categories {
}
