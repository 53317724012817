.react-draggable, .cursor {
    cursor: move;
  }
  .no-cursor {
    cursor: auto;
  }
  .cursor-y {
    cursor: ns-resize;
  }
  .cursor-x {
    cursor: ew-resize;
  }
  .react-draggable strong {
    background: #02874a;
    padding: 3px 5px;
    border: none;
    display: block;
    color: #fff;
    text-align: left;
    /* position: absolute; */
    z-index: 1;
    cursor: grab;
  }

  .react-draggable.small {
    padding-left: 10px;
  }

  .base-box {
    /* border-radius: 3px; */
    /* min-width: 250px; */
    /* max-width: 600px; */
    /* min-height: 250px; */
    float: left;
    overflow: hidden;
    /* padding: 3px; */
    background: #f2f2f2;
    border: 1px solid #ccc;
  }

  /* BROWN */
  .box {
    background: url('../images/background-body.png');
    border: none;
    border-radius: 3px;
    min-width: 300px;
    max-width: 600px;
    min-height: 200px;
    margin: 10px;
    padding: 10px;
    overflow: scroll;
  }

  .react-draggable > .eat {
    /* background-color: #599659; */
  }
  .react-draggable >.sell {
    background-color: #ff9;
    color: #5B4532;
  }
  .react-draggable > .compost {
    background-color: #5B4532;
  }

  .cropbox {
    border: 1px dashed #ddd;
    /* width: 155px;
    height: 40px; */
    padding: 10px;
    font-size: 10px;
    float: left;
    color: #333;
    box-shadow: inset 0 0 0 2px #eee;
  }

  /* .long-cropbox {
    border: 1px dashed #ddd;
    width: 70px;
    height: 150px;
    padding: 10px;
    font-size: 10px;
    float: left;
    color: #fff;
    box-shadow: inset 0 0 0 4px #eee;
  } */

  .status-plant {
    border: 4px solid #005A84;
  }

  .status-harvest {
    border: 4px solid #AF130A;
  }
 
  .status-growing {
    border: 4px solid #419639;
  }
  .destroy-icon {

    border-radius: 60px;
    top: 2px;
    z-index: 10000;
    right: 2px;
  }

  .planting-name {
    color: #fff;
    font-size: 11px;
    text-shadow: 1px 1px 1px #333;
    padding: 3px 8px;
    border-radius: 5px;
    text-rendering: optimizelegibility !important;

    line-height: 2;
    /* white-space: nowrap; */
    margin: auto;
    text-align: center;
    overflow: visible;
    position: relative;
    top: 25%;
    min-width: 60px;
  }

  .planting-controls {
    margin-top: -7px;
    background: #333;
    margin-right: -7px;
  }


   /* Eat */
  .cropbox--0,
  .cropbox--eat {
    background:#77AD67;
    color: white;
  }

  /* Sell */
  .cropbox--1,
  .cropbox--sell {
    background: #ffdf00;
    color: white;
  }

  /* Compost */
  .cropbox--2,
  .cropbox--compost {
    background: #987654;
    color: white;
  }

 
  .double-border {
    background-color: #ccc;
    border: 4px solid #fff;
    padding: 2em;
    width: 16em;
    height: 16em;
    position: relative;
    margin: 0 auto;
  }
  .double-border:before {
    background: none;
    border: 4px solid #fff;
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    pointer-events: none;
  }
